import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import ReactMarkdown from "react-markdown/with-html"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const TermsAndConditionsPageTemplate = ({
  title,
  content,
  subTitle,
  subText,
}) => (
  <>
    <SEO title="Terms and Conditions" />
    <Container fluid className="termsAndConditions py-5">
      <Row className="d-flex justify-content-center ">
        <Col xs={11}>
          <h1 className="text-center">{title}</h1>
          <h3 className="text-center mb-5">{subTitle}</h3>
          <p>{subText}</p>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Col>
      </Row>
    </Container>
  </>
)

const TermsAndConditionsPage = props => {
  const { frontmatter } = props.data.TermsAndConditionsPageTemplate
  console.log(frontmatter)
  return (
    <TermsAndConditionsPageTemplate
      title={frontmatter.title}
      content={frontmatter.content}
      subTitle={frontmatter.subTitle}
      subText={frontmatter.subText}
    />
  )
}

export default TermsAndConditionsPage

export const pageQuery = graphql`
  query($id: String!) {
    TermsAndConditionsPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
        subTitle
        subText
      }
    }
  }
`
